<template>
  <div class="home">
    <InstanceSummary v-for="(content,instance) in structure" :instance="instance" :structure="structure" v-bind:comparisonState="comparisonState" v-bind:is_checked="is_checked" v-bind:comparison_instance="comparison_instance" class="instance_summary"/>
    <div class="spacer"></div>
  </div>
  <Footer/>
</template>

<script>
// @ is an alias to /src
import InstanceSummary from '@/components/InstanceSummary.vue'
import Footer from '@/components/Footer.vue'
import contentStructure from "@/../public/data/structure.json";

export default {
  name: 'Home',
  components: {
    InstanceSummary,
    Footer
  },
  data () {
      return { 
        structure: contentStructure,
        comparisonState: {},
        is_checked: {},
        comparison_instance: ['none']
      }
  },
  mounted() {
      for (var instance in this.structure){
        this.comparisonState[instance] = 'no_comparison';
        this.is_checked[instance] = false;
      }
  },
}

</script>

<style scoped>
.home {
  position:relative;
  top:10em;
}
.description {
  text-align: left;
  background-color:#F8FBFD;
  border:0;
  border-radius: 1em;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  width:auto;
  padding:1em;
  border-radius: 0.5em;
  margin:0;
  margin-top:1em;
}
.spacer {
  clear:both;
  height:2.2em;
}
</style>
