<template>
    <div class=footer>
        <div class="footer-left s-nav-logos">
            <a href="https://www.ezlab.org/" target="_blank">EZ<em>lab</em></a>
            <a href="https://www.orthodb.org/" target="_blank">Ortho<em>DB</em></a>
            <a href="https://busco.ezlab.org/" target="_blank">BUSCO</a>
            <a href="https://lemmi.ezlab.org/" target="_blank">LEM<em>MI</em></a>
            <a href="https://lemmi16s.ezlab.org/" target="_blank">LEM<em>MI16s</em></a>
            <a href="https://mirmap.ezlab.org/" target="_blank">miR<em>map</em></a>
            <a href="http://cegg.unige.ch/newick_utils" target="_blank">Newick<em>Utils</em></a>
        </div>
        <div class="footer-right">
            &copy; 2022 EM Zdobnov Group -
            <a href="https://www.unige.ch/medecine/gede/en/research-groups/830zdobnov/">UNIGE</a>
            / <a href="https://www.sib.swiss/evgeny-zdobnov-group">SIB</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

.footer {
    position: fixed;
    bottom:0;
    left:0;
    font-size: 11px;
    width: 100%;
    min-width: 1250px;
    text-align: center;
    line-height: 20px;
    color: #222;
    background-color: #F7F7F7;
    z-index: 100;
}

.footer-left,
.footer-right {
    padding-top: 2px;
    line-height: 24px;
}

.footer-left {
    float: left;
    margin-left: 36px;
}

.footer-right {
    float: right;
    width: 380px;
    text-align: center;
}

.footer-left img {
    height: 32px;
    vertical-align: middle;
}

.s-nav-logos a {
    margin: 0 0.3em;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #b20;
    text-shadow: 0.1em 0.1em 0.1em #bbb;
    text-decoration: none!important;
    text-rendering: optimizeLegibility;
}

.s-nav-logos em {
    color: #936;
}

.s-nav-logos a:hover {
    color: #d30;
}

.s-nav-logos a:hover em {
    color: #c37;
}


</style>

