<template>
    <div class="floating_block">
    <h2>{{ niceTitle }}</h2>
    <div style="position: relative; width: 0; height: 0">
    <span class="is_new" :class="box_content.is_new">New</span>
    <span class="is_updated" :class="box_content.is_new">Updated</span>
    </div>
    <div class="instance_summary_description">
    <div class="paragraph">
    <b>{{ box_content.title }}</b>
    </div>
    </div>
    <div><router-link :to="{name: 'InstanceDetails', params: {instance: instance}}" class="explore" v-bind:class="comparisonState[instance]">>>&nbsp;See details</router-link></div>
    </div>
</template>

<script>
import niceTitles from "@/../public/data/nice_titles.json";

export default {
  name: 'InstanceSummary',
  props: {
    instance: String,
    is_checked: Object,
    comparisonState: Object,
    structure: Object,
    niceTitles: Object,
    comparison_instance: Object
  },
  data() {
    return {
      box_content: {
        "text": ""
      },
      niceTitle: niceTitles[this.instance] ? niceTitles[this.instance] : this.instance,
    }
  },
  mounted() {
      fetch(`data/${this.instance}.description.json`)
        .then(res => res.json())
        .then(data => this.box_content = data)
        .catch(error => this.box_content = {'text1': 'No description file can be found.'});
  },
  methods: {
    setCompare(current_instance) {
      var unchecking = false
      if (this.$props.is_checked[current_instance]){
        unchecking = true
      }
      for (var an_instance in this.$props.comparisonState) {
          if (unchecking){
              this.$props.comparisonState[an_instance] = 'no_comparison'
              this.$props.is_checked[an_instance] = false;
              this.$props.comparison_instance[0] = 'none';
          }
          else if (current_instance == an_instance){
              this.$props.comparisonState[an_instance] = 'to_compare_with'
              this.$props.is_checked[an_instance] = true;
              this.$props.comparison_instance[0] = an_instance;
          }
          else {
              this.$props.comparisonState[an_instance] = 'compare_with'
              this.$props.is_checked[an_instance] = false;
              this.$props.comparison_instance[0] = current_instance;
          }
      }
      //this.$props.comparisonState[dataset] = !this.isComparison[dataset];
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2 {
  display: block;
  font-size:1.3em;
  padding:0.3em;
  margin-bottom:1em;
  color:black;
  background-color:white;
  border-radius:1em;
}

.floating_block {
  width:16em;
  float:left;
  border: 2px solid white;
  box-shadow: 0 0 15px #e1f5fe;
  border-radius: 1em;
  padding: 1em;
  margin: 1em;
  background-color: #dae7f1;
  height: 15em;
}

.floating_block h2 {
  height: 2.6em;
  font-size: 1.1em;
  border: 1px solid black;
}

.floating_block td {
  font-size: 0.7em;
  font-weight: 500;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 0;
  padding:0.1em 0.15em;
  min-width:6.6em;
}

.NCBI {
  background-color: #0288d1;
  color:white;
}

.GTDB {
  background-color: #d84315;
  color:white;
}

.yes {
  background-color: #0288d1;
  color:white;
}

.date {
  background-color: #0288d1;
  color:white;
}

.no {
  background-color: #d84315;
  color:white;
}

.excluded {
  background-color: #d84315;
  color:white;
}

.included {
  background-color: #0288d1;
  color:white;
}


.Illumina {
  background-color: #0288d1;
  color:white;
}

.ONT {
  background-color: #d84315;
  color:white;
}

.clinical {
  background-color: #0288d1;
  color:white;
}

.environmental {
  background-color: #d84315;
  color:white;
}

.prokaryotes {
  background-color: #0288d1;
  color:white;
}

.viruses {
  background-color: #111;
  color:white;
}

.eukaryotes {
  background-color: #d84315;
  color:white;
}

.floating_block th {
  font-size: 0.7em;
  font-weight: 500;
  text-align: right;
  padding: 0.2em 0.5em;
  margin: 0;
  border: 0;
}

.floating_block tr {
  padding: 0;
  margin: 0;
  background-color: #FFF;
}

.floating_block table {
    border-spacing: 0px;
    border-collapse: collapse;
    margin-top: 0.2em;
    margin-bottom: 1em;
    width: 100%;
    border: 1px solid black;
}

.instance_summary_description {
  margin:0;
  padding:1em;
  width:14em;
  background-color: #F8FBFD;
  text-align:left;
  border:0.3em solid #0288d1;
  border-radius: 1em;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  height: 4em;
}

.explore.no_comparison {
  display:block;
}

.explore.to_compare_with {
  display:none;
}

.explore.compare_with {
  display:none;
}

.explore {
  font-variant: small-caps;
  font-weight: bold;
  margin-top:1em;
  background-color:white;
  border-radius:1em;
}

.compare.no_comparison {
  display:none;
}

.compare.to_compare_with {
  display:none;
}

.compare.compare_with {
  display:block;
}

.compare {
  font-variant: small-caps;
  font-weight: bold;
  margin-top:1em;
  background-color:white;
  border-radius:1em;
  font-size: 0.8em;
}

.select_compare.compare_with {
  display:none;
}

.select_compare {
  display:block;
  margin-top:1em;
  background-color:none;
  border-radius:1em;
  font-size: 0.8em;
}

.paragraph {
  font-size:0.8em;
  margin-bottom:0.9em;
  text-align:left;
}

.is_new {
  display:none;
}

.is_updated {
  display:none;
}

.is_new.yes {
  display:block;
  position:absolute;
  top: -7.5em;
  left: -2em;
  color:black;
  background-color:gold;
  width:3em;
  border-radius:1em;
  border:0.2em solid white;
  font-weight: 700;
  padding:0.1em;
}

.is_updated.updated {
  display:block;
  position:absolute;
  top: -7.5em;
  left: -2em;
  color:white;
  background-color:goldenrod;
  width:5em;
  border-radius:1em;
  border:0.2em solid white;
  font-weight: 500;
  padding:0.1em;
}

</style>
