import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'cookieconsent'
import 'cookieconsent/build/cookieconsent.min.css'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

var _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
var u="https://matomo.sib.swiss/";
_paq.push(['setTrackerUrl', u+'matomo.php']);
_paq.push(['setSiteId', '91']);
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();

var app = createApp(App)

app.use(router).mount('#app')

window.cookieconsent.initialise({
    "palette": {"popup": {"background": "#444444"}, "button": {"background": "#bbbbb0"}},
    "theme": "classic"
})
